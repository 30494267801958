/* MOBILE FIRST! (375px) *******/
body {
  font-size: 16px;
  color: #000000;
  font-family: "Red Hat Display", sans-serif;
}

.desktopShow {
  display: none;
}

.mobileSize14 {
  font-size: 0.875em;
}

.mobileSize16 {
  font-size: 1em;
}

.mobileSize18 {
  font-size: 1.125em;
}

.mobileSize20 {
  font-size: 1.25em;
}

.mobileSize22 {
  font-size: 1.375em;
}

.mobileSize25 {
  font-size: 1.6em;
}

.mobileSize30 {
  font-size: 1.875em;
}

/* font line-height */
.mobile14Line18 {
  line-height: 1.3em;
}
.mobile16Line21 {
  line-height: 1.3em;
}

.mobile16Line22 {
  line-height: 1.38em;
}

.mobile16Line28 {
  line-height: 1.75em;
}

.mobile18Line23 {
  line-height: 1.3em;
}

.mobile18Line28 {
  line-height: 1.6em;
}

.mobile20Line26 {
  line-height: 1.3em;
}

.mobile20Line34 {
  line-height: 1.7em;
}

.mobile22Line21 {
  line-height: 0.95em;
}

.mobile22Line35 {
  line-height: 1.6em;
}

.mobile25Line22 {
  line-height: 0.88em;
}

.mobile25Line33 {
  line-height: 1.3em;
}

.mobile30Line40 {
  line-height: 1.3em;
}

/* font margin */
.mobile16MarginB20 {
  margin-bottom: 1.25em;
}

.mobile18MarginB58 {
  margin-bottom: 3.2em;
}

.mobile20MarginB10 {
  margin-bottom: 0.5em;
}

.mobile20MarginB58 {
  margin-bottom: 2.9em;
}

.mobile25MarginB17 {
  margin-bottom: 0.68em;
}

.mobile30MarginB20 {
  margin-bottom: 0.67em;
}

.mobile30MarginB30 {
  margin-bottom: 1em;
}

.mobile30MarginB45 {
  margin-bottom: 1.5em;
}

.fontItalic {
  font-style: italic;
}

.noMarginB {
  margin-bottom: 0em;
}

.marginB1em {
  margin-bottom: 1em;
}

.marginB6px {
  margin-bottom: 6px;
}

.mobile1MarginT42 {
  margin-top: 2.63em;
}

.textCenter {
  text-align: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.rowBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.colorWhite {
  color: #ffffff;
}

.colorRed {
  color: #ad241d;
}

.colorDarkRed {
  color: #761612;
}

.colorGrey {
  color: #8e8e8e;
}

.colorBlack {
  color: #000000;
}

.weightBold {
  font-weight: 700;
}

.redLinks {
  color: #ad241d !important;
  font-weight: 700;
  text-decoration: underline;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
}

.redLinks:hover {
  color: #761612 !important;
}

.whiteLinks:hover {
  color: #ad241d !important;
}

/* RED BUTTONS */
.btn.redButton {
  font-size: 1em;
  min-width: 100%;
  height: 3.75em;
  background-color: #ad241d;
  border-color: #ad241d;
  box-shadow: #761612 0px 3px 0px;
  border-radius: 10px;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.btn.redButton.medium {
  margin-top: 0.25em;
}

.btn.redButton:hover,
.btn.redButton:focus,
.btn.redButton:active {
  height: 3.75em !important;
  background-color: #761612 !important;
  box-shadow: #323232 0px 3px 0px !important;
  border-color: #761612 !important;
}

.redButton .buttonText {
  color: #ffffff !important;
  text-decoration: none !important;
}

/* INPUTS */
.form-group {
  margin-bottom: 1.25em;
}

.form-label {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.inputs,
.inputs:focus {
  width: 100%;
  height: 3.5em;
  color: #000000;
  border: 1px solid #8e8e8e;
  border-radius: 3px;
  box-shadow: none;
}

.inputs::placeholder {
  color: #8e8e8e;
}

.formError .label {
  color: #ad241d;
}

.formError .inputs {
  border-color: #ad241d;
}

/* ul */
ul.redList {
  color: #761612;
  padding-inline-start: 1.2em !important;
}

ul.redList li {
  margin-bottom: 1em;
}

/* MODALS */
.modals {
  max-width: 84% !important;
  margin: auto;
  margin-top: 2.2em;
  border: 1px solid #707070;
}

.modal-content {
  border-radius: 0em;
  border: none;
}

.modals .inner {
  padding: 1.88em 1.25em 1.7em;
}

.modals .closeIconDiv {
  position: absolute;
  top: 0.69em;
  right: 0.63em;
}

.modals .closeIcon {
  width: 1.6em;
  height: 1.6em;
  cursor: pointer;
}

/* HEADERS */
.header {
  width: 100vw;
  padding: 0.375em;
  position: absolute;
  top: 0em;
}

.mobileHeader .menuIcon,
.hamburgerMenu .closeIcon {
  width: 1.6em;
  margin: 0em 1em;
}

.mobileHeader .logo,
.hamburgerMenu .logo {
  width: 9.2em;
  height: auto;
}
/********/

/* HAMBURGER MENU **********/
.hamburgerMenu {
  z-index: 2000;
  width: 100vw;
  max-width: 415px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -110vw;
  padding: 0.375em 1.88em 0.375em 0.375em;
  box-shadow: #3232328a 1px 0px 6px;
  background-color: #f9f9f9;
  -webkit-transition: left 0.3s ease-out;
  -moz-transition: left 0.3s ease-out;
  -o-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
}

.hamburgerMenu.open {
  left: 0;
}

.hamburgerMenu-list {
  display: block;
  list-style-type: none !important;
  padding-left: 1.5em;
  margin-top: 1.75em;
}

.hamburgerMenu-list li {
  height: 2.9em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  border-bottom: 3px solid #dadada;
}

.hamburgerMenu-list li.first {
  border-top: 3px solid #dadada;
}

.hamburgerMenu-list .links {
  font-size: 1.125em;
  line-height: 1.3em;
  color: #000000;
}

.hamburgerMenu-list .links:hover {
  color: #ad241d;
  text-decoration: none;
}
/***/

.mainContainer {
  background-color: #f9f9f9;
}

.jumboTron {
  width: 100vw;
  height: 100%;
  background-color: #323232;
  padding-top: 3.75em;
  padding-left: 1.875em;
  padding-right: 1.875em;
  background: url("./Styles/Images/headerImage.jpg");
  background-size: cover;
}

.heroHome {
  background: url("./Styles/Images/homepageHero.jpg");
  background-size: cover;
}

.jumboTron.tall {
  min-height: 100vh;
}

.jumboTron.short {
  min-height: 11em;
}

.mainContent {
  background-color: white;
  padding: 2em 1.875em 3.2em;
}

.sections {
  position: relative;
  padding-bottom: 2.5em;
}

.sections.last {
  padding-bottom: 0em !important;
}

/* HOMEPAGE */
/* SECTION 2 */
.home .sections.two .downloadColumn {
  margin-bottom: 2.5em;
}

/* STEPS SECTION  */
.home .sections.howItWorks {
  padding-bottom: 0em;
}

.home .stepsColumn {
  margin-bottom: 2.5em;
}

.home .stepsIcon {
  width: 6.25em;
  margin-bottom: 0.625em;
}

/* CAROUSEL */
/* .carousel-item {
  text-align: center;
}

.carousel-control-next, .carousel-control-prev {
  width: 10%;
} */

.BrainhubCarousel__customArrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* cursor: pointer; */
}

.carouselImages {
  width: 85%;
  height: auto;
}

.material-icons.carouselArrows {
  color: #8e8e8e;
  font-size: 1.9em;
}
/***/

.home .companies {
  padding-bottom: 1.88em;
}

/* CONTACT SECTION */
.home .contactDivs {
  margin-bottom: 1.25em;
}

.home .contactDivs.social {
  margin-bottom: 2.5em;
}

/* PARTNERS */
.partnerContainer img {
  width: 100%;
  padding: 20px;
}

/* DOWNLOAD QUESTIONAIRE PAGE */
.input-group.forCheckbox {
  width: auto;
  margin-right: 0.94em;
}

.input-group-text {
  background-color: #ffffff00;
  padding: 0em;
  border: none;
}

.squareOptions {
  width: 2.81em;
  height: 2.81em;
  border: 1px solid #0d0d0d;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearence: none;
  outline: none !important;
}

.squareOptions.checked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ad241d;
  border-color: #ad241d;
}

.squareOptions.checked::after {
  content: url("./Styles/Images/whiteCheckmark.png");
  padding-top: 0.3em;
}

.questionMark {
  width: 1.88em;
  height: 1.88em;
  margin-left: 0.88em;
}

.toolTips {
  font-family: "Red Hat Display", sans-serif !important;
  opacity: 1 !important;
}

.toolTips .tooltip-inner {
  max-width: 23em !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 0.7em 1.6em 0.9em;
}

.toolTips.greyToolTips .tooltip-inner {
  background-color: #f9f9f9 !important;
  color: #000000 !important;
}

.toolTips.greyToolTips .arrow::before {
  border-top-color: #f9f9f9 !important;
}

.questionaireForm {
  margin-top: 1.25em;
}

/* BECOME A MEMBER */
.becomeAMember .sections.memberBenefits {
  padding-bottom: 1.25em;
  margin-top: 50px;
}

.becomeAMember .sections.memberBenefits ul.redList {
  margin-top: 1.25em; /* 20px */
}

.becomeAMember .sections.quote {
  padding-bottom: 3.13em;
}

.becomeAMember .quoteIcons {
  width: 2.75em;
  height: 1.8em;
  position: absolute;
  top: 0.16em;
}

.becomeAMember .quoteIcons.left {
  left: -1.1em;
}

.becomeAMember .quote .text {
  padding: 0em 0.8em;
}

.becomeAMember .quoteIcons.right {
  right: -1.1em;
}

.becomeAMember .mobileShow .pcImg {
  width: 15.3em;
  height: auto;
}

.becomeAMember .vendorBenefits .redList {
  margin-bottom: 2.44em;
}

/* BOARD OF DIRCETORS */
.boardOfDirectors .mainContent {
  padding-bottom: 1.94em !important;
}

.directorsCard {
  width: 100%;
  margin: 1.25em 0em 2.5em;
}

.directorsCard .imageDiv {
  width: 100%;
  padding: 0.63em;
  border: 1px solid #dadada;
  margin-bottom: 0.88em;
}

.directorsCard .imageDiv .innerImg {
  width: 100%;
  height: auto;
}

.directorsCard .linkedinIcon {
  width: 1.88em;
  height: 1.88em;
}

/* FOOTER */
.footer {
  background-color: #f9f9f9;
  border-top: 1px solid #dadada;
  padding: 1.88em 1.88em 2.4em;
}

.footer .redButton {
  margin-bottom: 2.56em;
}

.footer .inputs::placeholder {
  font-weight: 700;
  color: #000000;
}

/* SMALL PHONES ***************/
@media screen and (max-width: 374px) {
  .jumboTron.tall {
    padding-top: 5.75em !important;
    padding-bottom: 2em !important;
  }
}

/* iPAD ********************/
@media screen and (min-width: 768px) {
  /* FONT SIZE */
  .desktopSize16 {
    font-size: 1em !important;
  }

  .desktopSize18 {
    font-size: 1.125em !important;
  }

  .desktopSize20 {
    font-size: 1.25em !important;
  }

  .desktopSize22 {
    font-size: 1.4em !important;
  }

  .desktopSize25 {
    font-size: 1.6em !important;
  }

  .desktopSize30 {
    font-size: 1.9em !important;
  }

  .desktopSize40 {
    font-size: 2.5em !important;
  }

  /* font line-height */
  .desktop16Line30 {
    line-height: 1.9em !important;
  }

  .desktop18Line23 {
    line-height: 1.3em !important;
  }

  .desktop20Line26 {
    line-height: 1.3em !important;
  }

  .desktop20Line28 {
    line-height: 1.4em !important;
  }

  .desktop20Line29 {
    line-height: 1.45em !important;
  }

  .desktop20Line30 {
    line-height: 1.5em !important;
  }

  .desktop20Line32 {
    line-height: 1.6em !important;
  }

  .desktop25Line23 {
    line-height: 0.92em !important;
  }

  .desktop25Line33 {
    line-height: 1.32em !important;
  }

  .desktop25Line40 {
    line-height: 1.6em !important;
  }

  .desktop30Line29 {
    line-height: 0.97em !important;
  }

  .desktop30Line40 {
    line-height: 1.3em !important;
  }

  .desktop40Line53 {
    line-height: 1.33em !important;
  }

  /* font margin */
  .desktop1MarginT69 {
    margin-top: 4.3em !important;
  }

  .desktop16MarginB60 {
    margin-bottom: 3.75em !important;
  }

  .desktop20MarginB40 {
    margin-bottom: 2em !important;
  }

  .desktop30MarginB23 {
    margin-bottom: 0.77em !important;
  }

  .desktop40MarginB23 {
    margin-bottom: 0.58em !important;
  }

  .desktop40MarginB50 {
    margin-bottom: 1.25em !important;
  }

  .redButton.medium {
    min-width: 17.5em !important;
  }

  .redButton.small {
    width: 12.5em !important;
  }

  /* MODALS */
  .modals {
    max-width: 68% !important;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
  }

  .modals .inner {
    padding: 2.88em 3.13em 3.13em !important;
  }

  /* jumbotron */
  .jumboTron .inner {
    width: 75% !important;
  }

  .jumboTron.tall {
    min-height: calc(100vh + 4.63em) !important;
  }

  .jumboTron.short {
    min-height: 27.75em !important;
  }

  /* mainContent */
  .mainContent {
    width: 75% !important; /* Should be 63% but too small */
    margin: -4.63em auto 5em !important;
    padding: 4.5em 4em 3.25em !important;
    border: 1px solid #dadada !important;
    border-bottom: 7px solid #dadada !important;
  }

  /* SECTIONS */
  .sections {
    padding-bottom: 3.75em !important;
  }

  /* HOMEPAGE */
  .home .jumboTron .title {
    margin-bottom: 2.23em !important;
  }

  .home .sections.howItWorks {
    padding-bottom: 1.25em !important;
  }

  .home .howItWorks .stepsColumn {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 2.5em !important;
  }

  .home .sections.two .downloadColumn {
    margin-bottom: 3.75em !important;
  }

  .home .howItWorks .stepsColumn .iconDiv {
    margin-right: 1.25em !important;
  }

  .home .companies {
    padding-bottom: 3.75em !important;
  }

  /* .carouselImages {
    width: 46% !important;
  } */

  .home .contactUs .title {
    margin-bottom: 1.5em !important;
  }

  .home .contactInfo {
    margin-bottom: 3.63em !important;
  }

  .home .form-group {
    margin-bottom: 2.2em !important;
  }

  /* BECOME A MEMBER */
  .becomeAMember .sections.one {
    padding-bottom: 5em !important;
  }

  .becomeAMember .memberBenefits .redList {
    margin-top: 2.43em !important;
  }

  .becomeAMember .sections.quote {
    padding-bottom: 5em !important;
  }

  .becomeAMember .vendorBenefits .redList {
    margin-bottom: 4em !important;
  }

  /* FOOTER */
  .footer {
    padding-top: 2.1em !important;
    padding-bottom: 2.5em !important;
  }
  .footer .rowOne,
  .footer .form {
    display: flex !important;
    flex-direction: row !important;
    flex: 1 !important;
    align-items: center !important;
  }

  .footer .rowOne {
    margin-bottom: 2.2em !important;
  }

  .footer .font20MarginB10 {
    margin-bottom: 0em !important;
  }

  .footer .form-group {
    width: 100% !important;
    padding: 0em 1.25em !important;
    margin-bottom: 0em !important;
  }

  .footer .redButton {
    margin-bottom: 0em !important;
  }
}

/* iPAD PRO - DESKTOP **********/
@media screen and (min-width: 1024px) {
  /* JumboTron */
  .jumboTron.tall {
    min-height: calc(100vh + 5.63em) !important;
  }

  /* mainContent */
  .mainContent {
    /* width: 63% !important; */
    margin: -5.63em auto 5em !important;
    padding: 5.5em 4em 3.25em !important;
  }

  /* HOMEPAGE */
  .home .sections.two .downloadColumn {
    margin-bottom: 0em !important;
  }

  /* BOARD OF DIRECTORS */
  .boardOfDirectors .mainContent {
    padding-bottom: 3.88em !important;
  }

  .boardOfDirectors .directorsCardWrappers {
    width: 46% !important;
  }

  .boardOfDirectors .directorsWrapper {
    display: flex !important;
    flex-direction: row !important;
    flex-flow: wrap !important;
    justify-content: space-between !important;
  }

  /* FOOTER */
  .footer .rowOne {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* DESKTOP *******************/
@media screen and (min-width: 1200px) {
  .mobileShow {
    display: none !important;
  }

  .desktopShow {
    display: block;
  }

  .partners .desktopShow {
    display: flex !important;
    flex-direction: column;
  }

  .redButton.small {
    min-width: 7.5em !important;
  }

  .twoColumnRow {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .twoColumnRowColumns {
    width: 46% !important;
  }

  /* HEADER */
  .header {
    padding-top: 1.2em !important;
    padding-bottom: 1.2em !important;
  }

  .desktopHeader {
    max-width: 1200px !important;
    padding: 0em 1em !important;
    margin: auto !important;
  }

  .desktopHeader .menuIcon {
    /* width: 8.63em !important; */
    width: auto !important;
    height: 5.31em !important;
  }

  .desktopHeader .nav-link {
    padding: 0.5rem 0.95em !important;
  }

  /* JUMBOTRON */
  .jumboTron {
    padding-top: 7.7em !important;
  }

  /* HOMEPAGE */
  .home .stepsWrapper {
    display: flex !important;
    flex-direction: row !important;
    flex-flow: wrap !important;
    justify-content: space-between !important;
  }

  .home .stepsWrapper .stepsColumn {
    width: 46% !important;
  }

  .home .aboutUs .redButton {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  /* .carouselImages {
    width: 20% !important;
  } */

  .home .contactInfo {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  /* PARTNERS */
  .becomeAMember .mainContent {
    padding-top: 3.75em !important;
  }

  .becomeAMember .desktopShow .pcImg {
    width: 100% !important;
    max-width: 32em !important;
  }

  .becomeAMember .auditReport .twoColumnRowColumns {
    width: 48% !important;
  }

  /* BECOME A MEMBER */
  .becomeAMember .twoColumnRow,
  .downloadQuestionaire .twoColumnRow {
    flex-flow: wrap !important;
  }

  .becomeAMember .desktopShow.two {
    margin-top: 0.44em !important;
    margin-bottom: 2.75em !important;
  }

  /*PARTNERS*/
  .partnerContainer .partnerRow {
    margin: 40px 0px;
  }
  .partnerContainer .imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .partnerContainer .imageContainer p {
    margin: 0px;
  }

  .partnerContainer .imageContainer .flexRow {
    margin-top: 20px;
  }

  /* .partnerContainer img {
    width: 70%;
  } */

  /* BOARD OF DIRECTORS */
  .boardOfDirectors .mainContent {
    padding: 5.5em 6.4em 3.9em !important;
  }

  .boardOfDirectors .directorsWrapper {
    justify-content: center !important;
  }

  .boardOfDirectors .directorsCardWrappers {
    width: 27% !important;
    margin-left: 1.38em !important;
    margin-right: 1.38em !important;
  }

  /* FOOTER */
  .footer .rowOne {
    width: 61% !important;
  }
}

/* DESKTOP */
@media screen and (min-width: 1280px) {
  /* HOMEPAGE */
  /* .home .contactDivs {
    flex: 1 !important;
  } */

  /* .home .contactDivs.social {
    justify-content: flex-end !important;
  } */
}
